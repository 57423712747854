<template>
  <div id="info">
    <div class="infoContent">
        <h5>个人信息管理</h5>
        <div class="circle">
            <el-upload
                class="avatar-uploader"
                :action="serverUrl"
                :show-file-list="false"
                :headers="header"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </div>
        <div class="table">
            <div class="name">
                <div class="input">
                    <i class="el-icon-user" /> 名称:
                    <el-input size="small" placeholder="请输入名称" v-model="userName"/>
                </div>
            </div>
        </div>
        <div class="table">
            <div class="name">
                <div class="input">
                    <i class="el-icon-edit" /> 密码:
                    <el-input size="small" show-password placeholder="请输入密码" v-model="password"/>
                </div>
            </div>
        </div>
        <div class="table">
            <div class="name">
                <el-button size="small" round @click="save()" > 保存 </el-button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  import utils from "@/config/config.js";
  export default {
    name: "info",
    components: {
    },
    props: {

    },
    data() {
      return {
        serverUrl: utils.url + '/api/fast/upload',
        header: {
            Authorization: this.$cookies.get('Authorization')
            // token: sessionStorage.token
        }, // 有的图片服务器要求请求头需要有token
        imageUrl: this.$store.state.circleUrl,
        userName: this.$store.state.userName,
        password: '',
        userId: '',
      };
    },
    mounted() {
       this.getUserInfo(); 
    },
    methods: {
        getUserInfo () {
            let _this = this
            $.ajax({
            type: 'GET',
            url: utils.url + '/api/user/getUserInfo',
            data: {},
            jsonp: utils.jsonp,
            success: function (data, state, ret) {
                if (ret.status === 200) {
                    if (data.code === 200) {
                        _this.$store.state.userName = data.data.nickName
                        _this.$store.state.circleUrl = data.data.headPortrait
                        _this.$store.state.isAdmin = data.data.isAdmin
                        _this.userName = data.data.nickName
                        _this.imageUrl = data.data.headPortrait
                        _this.id = data.data.id
                    }
                }
            },
            beforeSend: utils.beforeSend,
            xhrFields: utils.xhrFields
            })
        },
        handleAvatarSuccess(res, file) {
            if(res.code == 200){
                this.imageUrl = res.data.thumbnailUrl
            }else{
                this.$message.error(res.data.msg)
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        save() {
            const _this = this
            var parameters = {
                headPortrait: this.imageUrl,
                nickName: this.userName,
                userId: this.userId,
                password: this.password,
            }
            console.log(parameters);
            $.ajax({
                type: 'POST',
                url: utils.url + '/api/user/updateUserInfo',
                data: JSON.stringify(parameters),
                contentType: utils.contentType,
                jsonp: utils.jsonp,
                success: function (data, state, ret) {
                if (ret.status === 200) {
                    if (data.code === 200) {
                        _this.$message({
                            message: data.msg,
                            type: 'success'
                        })
                        _this.getUserInfo()
                    } else {
                    _this.$message.error(data.msg)
                    }
                } else {
                    _this.$message.error(ret.data)
                }
                },
                error: function (ret) {
                    if (ret.status === 403 || ret.status === 401) {
                        _this.routerPath('/login')
                    }
                },
                beforeSend: function (xhr) {
                    xhr.setRequestHeader = utils.setRequestHeader
                    xhr.withCredentials = true
                },
                xhrFields: {
                    withCredentials: true
                }
            })
        }
    },
  };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only lang= "scss"  lang="less"-->
<style scoped>
  #info {
    width: 100%;
    height: calc(100vh - 200px);
  }
  .infoContent {
    width: 450px;
    margin: 100px auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius:  30px 30px 30px 30px;
    background: #ffffff;
    padding: 50px;
  }
  .circle {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background: #f5f5f5;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #f5f5f5;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px;
    height: 50px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100%;
    height: 100%;
    display: block;
  }
  .table {
    width: 100%;
    height: 40px;
    padding: 5px;
  }
  .name {
    width: 97%;
    height: 40px;
    float: left;
    line-height: 40px;
    margin: 0 auto;
  }
  .input .el-input {
    width: 200px;
    margin: 0px 10px;
  }
  .table .name .el-button {
    width: 300px;
    color: #000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .table .name .el-button:hover {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
  }
</style>
